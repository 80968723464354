<template>
  <div class="body">
    <Navbar v-bind:show-scroll-points="false"/>

    <b-row align-v="center" class="rowp row1" cols="1" cols-lg="2">
      <b-col class="row1-col1">
        <h1 class="row1-title">
          {{ $t('infopage_row1_title_p1') }}
          <br>
          <span class="text-rect-highlight-orange">{{ $t('infopage_row1_title_p2') }}</span>
        </h1>

        <div>
          {{ $t('infopage_row1_text') }}
        </div>

        <a class="btn-video d-inline-flex align-items-center" href="https://www.youtube.com/watch?v=BtwtLwduYDo">
          <img src="/icon/icon_play.svg"/>
          <div style="font-weight: 600; font-size: 14px; margin-left: 14px;">
            {{ $t('basic_watch_video') }}
          </div>
        </a>

      </b-col>

      <b-col>
        <img alt="Showcase image" class="row1-img" src="/imgs/infopage_img_1.png">
      </b-col>
    </b-row>

    <div class="row2 ml-auto mr-auto">
      <h2>{{ $t('infopage_row2_title') }}</h2>
      <div v-html="$t('infopage_row2_text')">
      </div>
    </div>


    <b-row class="rowp" cols="1" cols-lg="2">

      <b-col class="row2-col">
        <img alt="kdynakout.cz Webapp" class="row2-col-img" src="/imgs/infopage_img_web.png">
        <h3 class="row2-col-title">{{ $t('infopage_webapp_title') }}</h3>
        <div class="row2-col-text">{{ $t('infopage_webapp_text') }}</div>

        <a class="row2-col-btn  row2-col-btn-search" href="https://kdynakoupit.cz">{{ $t('basic_start_searching') }}</a>
      </b-col>

      <b-col class="row2-col">
        <img alt="kdynakout.cz Mobile app" class="row2-col-img" src="/imgs/infopage_img_explorer.png">
        <h3 class="row2-col-title">{{ $t('infopage_mobile_title') }}</h3>
        <div class="row2-col-text">{{ $t('infopage_mobile_text') }}</div>

        <a class="row2-col-btn" href="https://play.google.com/store/apps/details?id=com.chmelva4.fremenexplorer">
          <img class="store-icon" src="/icon/google_play.svg"/>
        </a>
        <a class="row2-col-btn apple-icon" href="https://apps.apple.com/us/app/fremen-explorer/id1562737610">
          <img class="store-icon" src="/icon/apple_store.svg"/>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'Info',
  components: {
    Navbar
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/styles";

.rowp {
  padding-left: 5%;
  padding-right: 5%;
  margin-left: 0;
  margin-right: 0;
}

.row1 {
  margin-top: 32px;
}

.row1-title {
  color: @color-blue-dark;
  margin-bottom: 16px;
}

.row1-img {
  max-width: 95%;
  max-height: 720px;
}

.row1-col1 {
  text-align: center;
}

.btn-video {
  cursor: pointer;
  margin-top: 16px;
}

.row2 {
  margin-top: 60px;
  margin-bottom: 32px;
  width: 90%;
}

.row2-col {
  padding-bottom: 64px;
}

.row2-col-img {
  max-width: 100%;
  margin-bottom: 30px;
}

.row2-col-title {
  margin-bottom: 12px;
}

.row2-col-text {
  margin-bottom: 24px;
}

.row2-col-btn {

}

.row2-col-btn-search {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  box-shadow: none;
  border: none;
  border-radius: 7px;
  background-color: @color-green;
  color: @color-blue-dark;
  outline: none;
  height: 58px;
  width: 198px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.store-icon {
  transition: .5s;
  height: 64px;
}

.store-icon:hover {
  transform: scale(1.1);
}

@media (min-width: @lg) {

  .row1 {
    margin-top: 50px;
  }

  .row1-col1 {
    padding-right: 12.5%;
    text-align: left;
  }

  .row1-title {
    margin-bottom: 36px;
  }

  .btn-video {
    margin-top: 36px;
  }

  .row1-img {
    max-width: 80%;
  }

  .row2 {
    margin-top: 100px;
    margin-bottom: 60px;
    width: 50%;
  }

  .row2-col {
  }

  .row2-col-img {
    max-width: 80%;
  }

  .row2-col-title {
    margin-bottom: 16px;
  }

  .row2-col-text {
    min-height: 80px;
  }

  .row2-col-btn {
  }

  .apple-icon {
    margin-left: 24px
  }
}

</style>
